var scroll_start = 0;
var startchange = $('.first-section');
var offset = startchange.offset();
if (startchange.length){
    $(document).scroll(function() { 
        scroll_start = $(this).scrollTop();
        if(scroll_start > offset.top) {
            $(".navbar").css('background-color', '#ffffff');
            $(".navbar").css('box-shadow', '0 0 1rem rgba(0, 0, 0, 0.5)');
            $(".navbar").css('top', '0');
            $(".navbar").css('transition', 'all 0.3s');
            $(".navbar-brand .logo").css('width', '100px');
        } else {
            $('.navbar').css('background-color', 'transparent');
            $(".navbar").css('box-shadow', 'none');
            $(".navbar-brand .logo").css('width', '130px');
        }
    });
}